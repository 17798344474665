/** @jsx jsx */
import React from "react";
import { Styled, jsx } from "theme-ui";
import { Link } from "gatsby";
import "./bio-content.scss";
/**
 * Shadow me to add your own bio content
 */

export default () => (
  <div>
    <div sx={{ mb: 2 }}>
      <b>Chris Rotunno</b>. BSBA. Data Scientist. Not Actor John Belushi.
      {/* {" "}
      <Styled.a id="link" href="http://rotunno.io/">
        Chris Rotunno
      </Styled.a>
      . */}
      <br />
      {/* "Whoever controls the media, the images, contols the culture" -AG */}
      <Styled.a id="link" as={Link} to="/about">
        See my Personal Narrative
      </Styled.a>
    </div>
    <div sx={{ mb: 0 }}>
      <Styled.a id="link" href="https://mailchi.mp/ba70d1717e4c/rotunno">
        Subscribe!
      </Styled.a>
    </div>
  </div>
);
